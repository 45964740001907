import Cookies from 'js-cookie';

const wishlistCookieId = 'wishlistproducts';

class Wishlist {
    productIds = [];
    wishListCountElement = null;

    constructor() {
        const cookieContent = Cookies.get(wishlistCookieId);
        this.productIds = cookieContent ? JSON.parse(cookieContent) : [];
        this.wishListCountElement = document.querySelector('.js-wishlist-count');
        this.wishListCountElement.dataset.count = this.productIds.length;
    }

    add(id) {
        return new Promise((resolve, reject) => {
            if(this.productIds.includes(id)) 
            {
                reject();
                return;
            }
            this.productIds.push(id);
            this.save();
            resolve();
        });
    }
    
    remove(id)
    {
        return new Promise((resolve, reject) => {
            if(!this.productIds.includes(id)) 
            {
                reject();
                return;
            }

            const index = this.productIds.indexOf(id);
            this.productIds.splice(index, 1);
            this.save();
            resolve();
        });
    }

    contains(id)
    {
        return this.productIds.includes(id);
    }

    save() {
        this.wishListCountElement.dataset.count = this.productIds.length;
        Cookies.set(wishlistCookieId,JSON.stringify(this.productIds), {expires:3600});
    }
}

export default new Wishlist();
