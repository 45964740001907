import { loadProducts } from "./_ajaxProducts";

const removeFiltersButton = document.querySelector(".js-remove-filters");

removeFiltersButton?.addEventListener("click", (e) => {
	e.preventDefault();

	loadProducts(new URLSearchParams());
});

const addFiltering = () => {
	document.querySelectorAll(".js-filter-option").forEach((option) => {
		option.addEventListener("change", (e) => {
			let params = new URLSearchParams(window.location.search);
			params.delete("page");

			if (e.target.dataset.type == "sort") {
				if (e.target.checked) {
					params.set("sort_direction", e.target.value);
					params.set("sort_name", e.target.name);
				} else {
					params.delete("sort_name");
					params.delete("sort_direction");
				}
			} else {
				if (e.target.checked) {
					params.set(e.target.name, e.target.value);
				} else {
					params.delete(e.target.name);
				}
			}

			loadProducts(params);
		});
	});
};
addFiltering();

export default addFiltering;
