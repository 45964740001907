document.querySelectorAll(".js-categorylist").forEach((categorylist) => {
	const parent = categorylist.parentElement;
	const body = document.body;
	const header = document.querySelector(".js-header");

	const setOffset = (pixels) => {
		categorylist.style.top = `${pixels}px`;
	};

	
	window.addEventListener("scroll", (e) => {
        if (parent.getBoundingClientRect().top > header.offsetHeight + 20) {
			setOffset(0);
            return;
		}
            
        setOffset(-parent.getBoundingClientRect().top  + header.offsetHeight + 20);

        if(parent.getBoundingClientRect().bottom < categorylist.getBoundingClientRect().bottom)
        {
            setOffset(parent.offsetHeight - categorylist.offsetHeight);
        }
	});
});
