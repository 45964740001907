import axios from "axios";
import zilvercms from "./_utils";

const microCartItems = document.querySelector(".js-microcart-items");
const cartItemCount = document.querySelector(".js-cart-item-count");

const form = microCartItems;

const updateMicrocart = async () => {
	let cartRequest1 = null;
	let cartRequest2 = null;

	await Promise.all([
		axios.get("/bjax/webshop/Getobjectasjson/cart/"),
		axios.get("/cart/?ajax=true")
	]).then(values => {
		cartRequest1 = values[0];
		cartRequest2 = values[1];
	});

	if (cartRequest1.status != 200 || cartRequest2.status != 200) return;

	const cartHtml = document.createElement('div');
	const stringJSON = cartRequest2.data;

	cartHtml.innerHTML = stringJSON;

	const cartData = stringJSON;
	const cartProductData = cartRequest1.data;
	const items = cartProductData.items;

	let microCartContent = "";
	if (items.length > 0) {
		let itemCount = 0;

		items.forEach((product) => {
			itemCount += parseInt(product.count);
		});

		cartItemCount.dataset.count = itemCount;

		const totalCartItems = items.length;

		if (items.length > 4) items.length = 4;

		items.forEach((product) => {
            const cartId = cartData.items.filter(x => x.productId == product.product_id)[0].cartId;

			microCartContent += `
                <div class="hstack gap-2 justify-content-between text-decoration-none js-microcart-product" data-count="${product.count}" >
                    <a href="${product.product_parent_url ? product.product_parent_url : product.product_url}" class="ratio ratio-16x13 bg-white overflow-hidden w-65px no-image-effect">
                        <img src="${zilvercms.imageHelper(product.image_url, 'x100')}" alt="${
							product.image_alt
						}" class="top-0 start-0 bottom-0 end-0 position-absolute contain"/>
                    </a>
                    <div class="me-auto vstack gap-1 justify-content-center text-truncate">
                        <div class="fw-700 text-secondary">${zilvercms.currencyHelper(
							product.current_price / product.count
						).replace('&euro;','')}</div>
                        <a href="${product.product_parent_url ? product.product_parent_url : product.product_url}" class="text-muted fs-200 text-truncate text-decoration-none">${
							product.name
						}</a>
                        <div class="fw-500 fs-200 text-primary">Aantal: ${
							product.count
						}</div>
                    </div>
					<div class="pointer-hover js-remove-cart-item opacity-50" data-product-id="${cartId}" style="flex: 0 0 14px;">
						<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18"><path d="M9.12 7.47 7 9.59 4.87 7.47 3.46 8.88 5.59 11l-2.12 2.12 1.41 1.41L7 12.41l2.12 2.12 1.41-1.41L8.41 11l2.12-2.12-1.41-1.41ZM10.5 1l-1-1h-5l-1 1H0v2h14V1h-3.5ZM1 16c0 .5304.21071 1.0391.58579 1.4142C1.96086 17.7893 2.46957 18 3 18h8c.5304 0 1.0391-.2107 1.4142-.5858S13 16.5304 13 16V4H1v12ZM3 6h8v10H3V6Z" fill="#000"/></svg>
					</div>
                </div>
				
                <hr class="light"></hr>
            `;
		});

		if(totalCartItems > 4) {
			const remainingItems = totalCartItems - 4;
			microCartContent += `
				<span class="text-muted fs-200"> En ${ remainingItems } ${ remainingItems > 1 ? "andere producten" : "ander product"} </span>
				<hr class="light"/>
			`;
		}
	} else {
		cartItemCount.dataset.count = 0;
        microCartContent += `
            <div>Er zitten nog geen producten in je winkelmand</div>
            <hr class="light"></hr>
        `
	}

//<div class="button align-self-start js-remove-cart-item" data-product-id="${product.product_id}"><svg width="9" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1.11218 7.88782 0 4.5 3.38782 1.11218 0 0 1.11218 3.38782 4.5 0 7.88782 1.11218 9 4.5 5.61218 7.88782 9 9 7.88782 5.61218 4.5 9 1.11218Z" fill="#000"/></svg></div>

	microCartItems.innerHTML = microCartContent;

	microCartItems
		.querySelectorAll(".js-microcart-product")
		.forEach((product) => {

			const closeButton = product.querySelector('.js-remove-cart-item');

			closeButton.addEventListener("click", async () => {
				const formData = new FormData();
				formData.append("command", "updatecart");
				formData.append("cartitem_count_" + closeButton.dataset.productId, parseInt(product.dataset.count) - 1);

				let response = await axios.post("/cart/?ajax=true", formData);

				if(response.status === 200)
				{
					updateMicrocart();
				}
			});
		});
};

updateMicrocart();

export default updateMicrocart;
