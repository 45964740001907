document.querySelectorAll("[data-popover-target]").forEach((button) => {
	const target = document.querySelector(`#${button.dataset.popoverTarget}`);

	const checkClickOutside = (event) => {
		if (target !== event.target && !target.contains(event.target) && !document.querySelector('.sooqrSearchContainer').contains(event.target)) {
			closePopover();
		}
	};

	const closePopover = () => {
		button.classList.remove('active');
		target.classList.remove("show");
		setTimeout(() => {
			button.addEventListener("click", openPopover);
		}, 20);
		document.removeEventListener("click", checkClickOutside);
	}

	const openPopover = () => {
		button.classList.add('active');
		button.removeEventListener("click", openPopover);
		target.classList.add("show");
        setTimeout(() => {
            document.addEventListener("click", checkClickOutside);
        }, 20);
	};

	button.addEventListener("click", openPopover);
	const closeButton = target.querySelector(".js-close");
	closeButton?.addEventListener('click', closePopover);
});
