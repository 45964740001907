if (
	navigator.userAgent.match(/(iPhone)/) &&
	navigator.userAgent.match(/AppleWebKit/)
) {
	let fromY;
	let fromX;

	let currentY;
	let currentX;

	$("body").on("touchstart", "a", function (e) {
		fromX = e.touches[0].clientX;
		fromY = e.touches[0].clientY;
		currentX = fromX;
		currentY = fromY;
	});

	$("body").on("touchmove", "a", function (e) {
		currentX = e.touches[0].clientX;
		currentY = e.touches[0].clientY;
	});

	$("body").on("touchend", "a", function (e) {
		if (
			Math.abs(fromX - currentX) < 10 &&
			Math.abs(fromY - currentY) < 10
		) {
			var el = $(this);
			var link = el.attr("href");
			if (link) window.location = link;
		}
	});
}
