import axios from "axios";
import toggleBackdrop from "./_backdrop";
import zilvercms from "./_utils";

const menu = document.getElementsByClassName("js-menu");
const mobileMenu = document.getElementsByClassName("js-mobile-menu");
const mobileMenuTrigger = document.getElementsByClassName("js-mobile-menu-trigger");

if(menu.length > 0){
    const getMenu = async () => {

        let response = await axios.get('/ajax/');
        let fullMenu = document.createElement('div');
        let mobilleFullMenu = document.createElement('div');
        let splittedMenu = response.data.split("___");

        fullMenu.innerHTML = splittedMenu[0];
        mobilleFullMenu.innerHTML = splittedMenu[1];
        menu[0].appendChild(fullMenu);
        mobileMenu[0].appendChild(mobilleFullMenu);

        initNavigation();
    }

    getMenu();
}

mobileMenuTrigger[0].addEventListener('click',() => {
    const html = document.querySelectorAll("html")[0];
    if(html.classList.contains('menu-open')){
        zilvercms.unlockBody();
        html.classList.remove('menu-open');
    }else{
        zilvercms.lockBody();
        html.classList.add('menu-open');
    }
});

function initNavigation() {

    const menuButtons = document.querySelectorAll("[data-category-button-id]");
    let selectedSubmenu = null;
    let selectedButton = null;

    menuButtons.forEach((button) => {
        const id = button.dataset.categoryButtonId;
        let hoverTimer = null;
        button.addEventListener("mouseover", () => {
            if (selectedButton == button) return;

            if (selectedSubmenu != null) {
                clearMenu();
            }

            hoverTimer = setTimeout(() => {
                selectedButton = button;

                const dropdownItem = document.querySelector(
                    `[data-category-dropdown-id="${id}"]`
                );

                selectedButton.classList.add("active");
                selectedSubmenu = dropdownItem;

                document.addEventListener("mousemove", handleMouseTracking);

                dropdownItem.classList.remove("d-none");
                toggleBackdrop(true);
            }, 300);
        });

        button.addEventListener("mouseout", (e) => {
            clearTimeout(hoverTimer);
        });
    });

    const clearMenu = () => {
        selectedSubmenu.classList.add("d-none");
        selectedSubmenu = null;
        selectedButton.classList.remove("active");
        selectedButton = null;
        toggleBackdrop(false);
    };

    const handleMouseTracking = (e) => {
        if (!selectedButton) return;

        if (
            !checkMouseInElement(e.clientX, e.clientY, selectedButton) &&
            !checkMouseInElement(e.clientX, e.clientY, selectedSubmenu)
        ) {
            clearMenu();
        }
    };

    const checkMouseInElement = (mouseX, mouseY, element) => {
        const rect = element.getBoundingClientRect();

        return (
            mouseX >= rect.left &&
            mouseX <= rect.right &&
            mouseY >= rect.top &&
            mouseY <= rect.bottom
        );
    };

    let currentMenuIndex = 0;

    document.querySelectorAll('button[data-menu-target]').forEach(button => {
        button.addEventListener('click', (event) => {
            const newIndex = button.dataset.menuTarget;

            const currentMenu = document.querySelector(`[data-menu-id="${currentMenuIndex}"`);
            const targetMenu = document.querySelector(`[data-menu-id="${newIndex}"`);

            currentMenu.classList.add('d-none');
            targetMenu.classList.remove('d-none');

            currentMenuIndex = newIndex;
        });
    });

}
