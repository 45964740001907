const backdropElement = document.querySelector('.backdrop');

let backdropIsActive = false;

const toggleBackdrop = (show = undefined) => 
{
    if (typeof show !== 'undefined')
    {
        backdropIsActive = !backdropIsActive;
    }
    else backdropIsActive = show;

    backdropElement.classList.toggle('show', backdropIsActive);
}

export default toggleBackdrop;