document.querySelectorAll('.js-pagination-link').forEach(link => {
    link.addEventListener('click', e => {
        e.preventDefault();

        var urlParameters = new URLSearchParams(window.location.search);
		urlParameters.set('page', link.dataset.page);

        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParameters.toString();

        window.location = newurl;
    })
});