window.initiateTranslation = async () => {
	new google.translate.TranslateElement(
		{
			pageLanguage: "nl",
		},
		"google-translate-element"
	);

	const languageSelect = document.querySelector(".goog-te-combo");

	while(!languageSelect.options.length)
	{
		await new Promise(resolve => setTimeout(resolve, 50));
	}

    if(document.querySelector('#goog-gt-tt h1') != null) {
        document.querySelector('#goog-gt-tt h1').remove();
    }

	const languageOptions = [...languageSelect.options];

	//Array with blacklisted languages, to prevent wrong flag from showing
	const languagesWithNoFlag = ["af"];

	const evt = document.createEvent("HTMLEvents");
	evt.initEvent("change", false, true);

	const flagImages = [
		...document.querySelectorAll(".js-translation-button img"),
	];

	const stopTranslationButtons = [
		...document.querySelectorAll(".js-stop-translation")
	];

	const translationChevron = [
		...document.querySelectorAll(".js-translation-chevron")
	]

	stopTranslationButtons.map((button) => {
		button.addEventListener("click", () => {
			eraseCookie("googtrans");
			window.location.reload();
		});
	});

	flagImages.map((img) => {
		const locale = img.dataset.locale;

		if (languagesWithNoFlag.includes(locale)) {
			img.src = "";
		}
		img.dataset.locale = locale;
	});

	[...document.querySelectorAll(".js-translation-options")].forEach(
		(translationsParent) => {
			languageOptions.map((option) => {
				if (option.value) {
					translationsParent.insertAdjacentHTML(
						"beforeend",
						`<li><button class="dropdown-item js-translation-option" data-code="${option.value}">${option.label}</button></li>`
					);
				}
			});
		}
	);

	const changeFlag = (locale) => {
		languageSelect.value = locale;
		languageSelect.dispatchEvent(evt);

		flagImages.map((img) => {
			if (languagesWithNoFlag.includes(locale)) {
				img.src = "";
			} else {
				img.src = `https://60cafc33af40b8287ffb-1a032c1af87160c7fc01b9de8fd3221e.ssl.cf3.rackcdn.com/flags/${locale}.png`;
			}
			img.dataset.locale = locale;
		});

		if (locale != "nl") {
			stopTranslationButtons.forEach((button) => {
				button.classList.remove("d-none");
			});
			translationChevron.map(chevron => {
				chevron.classList.add('d-none');
			})
		} else {
			stopTranslationButtons.forEach((button) => {
				button.classList.add("d-none");
			});
			translationChevron.map(chevron => {
				chevron.classList.remove('d-none');
			})
		}
	};

	document.querySelectorAll(".js-translation-option").forEach((option) => {
		option.addEventListener("click", () => {
			changeFlag(option.dataset.code);
		});
	});

	const eraseCookie = (name) => {
		document.cookie =
			name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;";
		document.cookie =
			name +
			"=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.zilvercms.nl";
		document.cookie =
			name +
			"=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=" +
			window.location.host;
	};

	setTimeout(() => {
		const langValue = document.querySelector('html').getAttribute('lang');
		changeFlag(langValue);
	}, 50);
};


