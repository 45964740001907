import { loadProducts, isLoading } from "./_ajaxProducts";

class LazyList {
	lazyListElement;
	currentPage = 1;
	pageCount = 0;
	isLoading = false;
	done = false;

	constructor(element) {
		this.lazyListElement = element;
		
		this.pageCount = element.dataset.pageCount;

		const params = new URL(document.location).searchParams;
		const pageParam = params.get("page");
		if (pageParam && parseInt(pageParam) > 1) this.disable();

		document.querySelector('.js-lazylistenable')?.addEventListener("click", e => {
			e.target.parentElement.remove();
			this.enable();
		})
	}

	loadNextPage = async () => {
		this.currentPage++;

		var urlParameters = new URLSearchParams(window.location.search);
		urlParameters.set("page", this.currentPage);

		this.isLoading = true;
		loadProducts(urlParameters, false).then((amount) => {
			if (amount == 0) {
				this.disable();
				this.currentPage--;
				urlParameters.set("page", currentPage);
			}
		}).finally(() => {
			this.isLoading = false;
		})
	};

	reset = () => {
		this.currentPage = 1;
		this.done = false;
	};

	setPageCount = (count) => {
		this.pageCount = count;
	};

	disable = () => {
		this.done = true;
		this.currentPage = 1;
	};

	enable = () => {
		const body = document.body;
		this.loadNextPage();
        window.addEventListener(
			"scroll",
			() => {
				if (
					this.isLoading ||
					this.currentPage == this.pageCount ||
					this.done
				)
					return;
				if (
					body.scrollTop + lazyListElement.getBoundingClientRect().bottom > body.scrollTop + document.documentElement.clientHeight
				)
					return;

				this.loadNextPage();
			},
			false
		);
	};
}

const lazyListElement = document.querySelector(".js-lazylist");
const lazyList = lazyListElement ? new LazyList(lazyListElement) : null;

export default lazyList;
