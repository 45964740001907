import wishlist from "./_wishlist";

document.querySelectorAll(".js-wishlist-trigger").forEach((wishlistTrigger) => {
	const productId = parseInt(wishlistTrigger.dataset.productId);
    
	if (wishlist.contains(productId))
		wishlistTrigger.classList.add("active");
});

$("body").on("click", ".js-wishlist-trigger", async (e) => {
	e.preventDefault();
	const trigger = e.currentTarget;
	const productId = parseInt(trigger.dataset.productId);

	if (trigger.classList.contains("active")) {
		wishlist.remove(productId).then(() => {
			trigger.classList.remove("active");
		});
	} else {
		wishlist.add(productId).then(() => {
			trigger.classList.add("active");
		});
	}
});
