const header = document.querySelector(".js-header");
const parent = header.parentElement;
const menu = document.querySelector("#offcanvasMenu");

function getYOffset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop
}

const updateHeader = () => {
    if(window.scrollY > getYOffset(parent)){
        header.classList.add('position-fixed');
        header.classList.add('fixed-top');
        parent.style.height = header.offsetHeight + 'px';
        header.style.right = document.body.offsetWidth - document.body.clientWidth + 'px'
    }
    else{
        header.classList.remove('position-fixed');
        header.classList.remove('fixed-top');
        parent.style.height = 'auto';
    }
};

const updateMenu = () => {
    const viewportOffset = header.getBoundingClientRect();

    menu.style.top = viewportOffset.bottom + 'px';
}

updateHeader();
updateMenu();

window.addEventListener("scroll", () => {updateHeader(); updateMenu()});
window.addEventListener("resize", () => {updateHeader(); updateMenu()});
