import utils from "./_utils";

const defaultOptions = {
    sliderTrackSelector: ".js-slider-track",
    sliderCurrentSelector: ".js-slider-current",
    prevArrowSelector: '.js-arrow-control[data-direction="left"]',
    nextArrowSelector: '.js-arrow-control[data-direction="right"]',
    dotsSelector: ".js-slider-dots",

    initialSlide: 0,
    arrows: true,
    dots: false,
    centerMode: false,
    viewports: { sm: {}, md: {}, lg: {}, xl: {}, xxl: {} },
};

class Slider {
    constructor(sliderElement, options = {}) {
        if ([null, undefined].includes(sliderElement)) {
            console.error("Slider element in defined");

            return;
        }

        this.options = {
            ...defaultOptions,
            ...options,
            viewports: {
                ...defaultOptions.viewports,
                ...options.viewports,
            },
            callbackParameters: {
                change: ["currentSlide"],
            },
        };

        this.states = {
            currentSlide: 0,
            isDragging: false,
            dragPosition: { left: 0, x: 0 },
        };

        this.sliderElement = sliderElement;
        this.sliderTrackElement = this.sliderElement.querySelector(this.options.sliderTrackSelector);
        this.sliderCurrentElement = this.sliderElement.querySelector(this.options.sliderCurrentSelector);

        this.slides = [...this.sliderTrackElement.children];

        if (this.getViewportOption("arrows")) {
            this.prevArrowElement = this.sliderElement.querySelector(this.options.prevArrowSelector);
            this.nextArrowElement = this.sliderElement.querySelector(this.options.nextArrowSelector);

            this.prevArrowClickHandler = this.prevArrowClickHandler.bind(this);
            this.nextArrowClickHandler = this.nextArrowClickHandler.bind(this);

            this.initializeArrows();
        }

        if (this.getViewportOption("dots")) {
            this.dotsElement = this.sliderElement.querySelector(this.options.dotsSelector);

            this.dotsDotClickHandler = this.dotsDotClickHandler.bind(this);

            this.initializeDots();
        }

        this.trackMouseDownHandler = this.trackMouseDownHandler.bind(this);
        this.trackMouseMoveHandler = this.trackMouseMoveHandler.bind(this);
        this.trackMouseUpHandler = this.trackMouseUpHandler.bind(this);
        this.trackScrollHandler = this.trackScrollHandler.bind(this);

        this.setInitialClass();
        this.setEvents();

        setTimeout(() => this.goToSlide(this.options.initialSlide, true, false), 50);
    }

    on(event, callback) {
        this.sliderElement.addEventListener(`slider.${event}`, () => {
            callback(...this.onCallbackParameters(event));
        });
    }

    onCallbackParameters(event) {
        return this.options.callbackParameters[event].map((option) => this.states[option]);
    }

    getViewportOption(name) {
        const viewports = Object.keys(this.options.viewports).reverse();
        const currentViewport = utils.viewport.is();
        const currentViewportIndex = viewports.indexOf(currentViewport);

        let value = this.options[name];

        if (currentViewport !== "xs") {
            for (let index in viewports) {
                const viewport = viewports[index];

                if (index >= currentViewportIndex) {
                    if (this.options.viewports[viewport][name] !== undefined) {
                        value = this.options.viewports[viewport][name];
                        break;
                    }
                }
            }
        }

        return value;
    }

    updateCurrentSlide(index) {
        this.states.currentSlide = index;

        if (this.sliderCurrentElement !== null) {
            this.sliderCurrentElement.innerText = this.states.currentSlide + 1;
        }

        if (this.getViewportOption("arrows")) {
            if (this.sliderTrackElement.clientWidth >= this.sliderTrackElement.scrollWidth) {
                this.prevArrowElement.style.visibility = "hidden";
                this.nextArrowElement.style.visibility = "hidden";
                this.prevArrowElement.setAttribute("disabled", "");
                this.nextArrowElement.setAttribute("disabled", "");
            } else {
                this.prevArrowElement.style.removeProperty("visibility");
                this.nextArrowElement.style.removeProperty("visibility");

                if (this.states.currentSlide === 0) {
                    this.prevArrowElement.setAttribute("disabled", "");
                } else {
                    this.prevArrowElement.removeAttribute("disabled");
                }

                const lastSlide = this.slides[this.slides.length - 1];

                if (lastSlide.offsetLeft + lastSlide.clientWidth < this.sliderTrackElement.scrollLeft + this.sliderTrackElement.clientWidth + 10) {
                    this.nextArrowElement.setAttribute("disabled", "");
                } else {
                    this.nextArrowElement.removeAttribute("disabled");
                }
            }
        }

        if (this.getViewportOption("dots")) {
            const dots = [...this.dotsElement.children];

            dots.map((dot, index) => {
                if (index === this.states.currentSlide) {
                    dot.setAttribute("data-current", "");
                } else {
                    dot.removeAttribute("data-current");
                }
            });
        }
    }

    goToSlide(index, force = false, animation = true) {
        if (!force) {
            if (index === this.states.currentSlide) {
                return false;
            }

            const isDirectionForward = index > this.states.currentSlide;
            let foundSlide = false;

            const currentScrollLeft = this.sliderTrackElement.scrollLeft;

            while (!foundSlide) {
                if (isDirectionForward) {
                    if (index > this.slides.length - 1) {
                        index = this.slides.length - 1;
                        foundSlide = true;
                    } else if (this.slides[index].offsetLeft <= currentScrollLeft) {
                        index = index + 1;
                    } else {
                        foundSlide = true;
                    }
                } else {
                    if (index < 0) {
                        index = 0;
                        foundSlide = true;
                    } else if (this.slides[index].offsetLeft >= currentScrollLeft) {
                        index = index - 1;
                    } else {
                        foundSlide = true;
                    }
                }
            }
        } else {
            if (index < 0) {
                index = 0;
            } else if (index > this.slides.length - 1) {
                index = this.slides.length - 1;
            }
        }

        this.updateCurrentSlide(index);
        this.sliderTrackElement.scrollTo({
            left: this.slides[index].offsetLeft,
            behavior: animation ? "smooth" : "auto",
        });

        this.sliderElement.dispatchEvent(new Event("slider.change"));
    }

    prevArrowClickHandler() {
        this.goToSlide(this.states.currentSlide - 1);
    }

    nextArrowClickHandler() {
        this.goToSlide(this.states.currentSlide + 1);
    }

    dotsDotClickHandler(e) {
        this.goToSlide([...this.dotsElement.children].indexOf(e.currentTarget));
    }

    trackMouseDownHandler(e) {
        //e.preventDefault();

        this.states.isDragging = true;

        this.sliderTrackElement.style.cursor = "grabbing";

        this.states.dragPosition.left = this.sliderTrackElement.scrollLeft;
        this.states.dragPosition.x = e.clientX;

        document.addEventListener("mousemove", this.trackMouseMoveHandler);
        document.addEventListener("mouseup", this.trackMouseUpHandler);
    }

    trackMouseMoveHandler(e) {
        if (e.clientX > this.states.dragPosition.x + 5 || e.clientX < this.states.dragPosition.x - 5) {
            this.sliderElement.setAttribute("data-dragging", "");
        }

        this.sliderTrackElement.scrollLeft = this.states.dragPosition.left - (e.clientX - this.states.dragPosition.x);
    }

    trackMouseUpHandler() {
        this.trackScrollHandler();

        this.states.isDragging = false;
        this.sliderTrackElement.style.removeProperty("cursor");

        setTimeout(() => this.sliderElement.removeAttribute("data-dragging"), 50);

        document.removeEventListener("mousemove", this.trackMouseMoveHandler);
        document.removeEventListener("mouseup", this.trackMouseUpHandler);
    }

    trackScrollHandler() {
        clearTimeout(this.sliderTrackElement.dataset.scrollTimeout);

        const scrollLeft = this.sliderTrackElement.scrollLeft;
        const lastSlideIndex = this.slides.length - 1;
        const lastSlide = this.slides[lastSlideIndex];

        let index = 0;

        if (this.states.currentSlide !== lastSlideIndex && lastSlide.offsetLeft + lastSlide.clientWidth === scrollLeft + this.sliderTrackElement.clientWidth) {
            index = lastSlideIndex;
        } else {
            for (let i = 0; i < this.slides.length; i++) {
                const slide = this.slides[i];

                if (slide.offsetLeft + slide.clientWidth * 0.5 >= scrollLeft) {
                    index = i;
                    break;
                }
            }
        }

        this.updateCurrentSlide(index);
        this.sliderTrackElement.dataset.scrollTimeout = setTimeout(() => this.goToSlide(index, true), 200);
    }

    setEvents() {
        this.sliderTrackElement.addEventListener("scroll", this.trackScrollHandler);
        this.sliderTrackElement.addEventListener("mousedown", this.trackMouseDownHandler);
    }

    initializeArrows() {
        this.prevArrowElement.classList.add("slider__arrow", "slider__arrow--prev");
        this.nextArrowElement.classList.add("slider__arrow", "slider__arrow--next");

        this.prevArrowElement.addEventListener("click", this.prevArrowClickHandler);
        this.nextArrowElement.addEventListener("click", this.nextArrowClickHandler);
    }

    initializeDots() {
        this.dotsElement.classList.add("slider__dots");

        for (let i = 0; i < this.slides.length; i++) {
            const dot = document.createElement("li");
            dot.classList.add("slider__dot");
            dot.addEventListener("click", this.dotsDotClickHandler);

            if (i === this.states.currentSlide) {
                dot.setAttribute("data-current", "");
            }

            this.dotsElement.appendChild(dot);
        }
    }

    setInitialClass() {
        this.sliderElement.classList.add("slider");
        this.sliderTrackElement.classList.add("slider__track");

        this.slides.map((slide) => slide.classList.add("slider__slide"));
    }
}

export default Slider;
