const { right } = require("@popperjs/core");

document.querySelectorAll('.js-arrow-control').forEach(arrow => {
    const direction = arrow.dataset.direction;
    const target = document.getElementById(arrow.dataset.target);
    const event = new CustomEvent('move', {
        detail: {
            direction: () => direction
        }
    })

    arrow.addEventListener('click', (e) => {
        target.dispatchEvent(event);
    })

    target.addEventListener('ondrag', () => {
        update();
    });

    const update = () => {
        if(!target.dataset.sliderWidth) return;
        
        const disableButton = direction == 'left' ? target.dataset.startReached : target.dataset.endReached != null && target.dataset.endReached;
        
        
        let contentIsLargerThanSlider = false;

        if(window.innerWidth < 768 && target.dataset.contentWidth <= 0)
            contentIsLargerThanSlider = true;
        else
            contentIsLargerThanSlider = parseInt(target.dataset.contentWidth) > parseInt(target.dataset.sliderWidth) + 5;



        arrow.classList.toggle('d-none', !contentIsLargerThanSlider);
    }
    
    window.addEventListener("resize", update);

    update();
})