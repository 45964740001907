document.querySelectorAll('.carousel').forEach(carousel => {
    const id = carousel.id;

    const items = carousel.querySelectorAll('.carousel-item');
    if(items.length) items[0].classList.add('active');

    if(items.length > 1)
    {
        const carouselIndicatorParents = carousel.querySelector('.carousel-indicators');


        for(let i = 0; i < items.length; i++)
        {
            const button = document.createElement('button');
            if(i == 0) button.classList.add('active');
            button.type = 'button';
            button.dataset.bsTarget = `#${id}`;
            button.dataset.bsSlideTo = i;
            button.ariaLabel = `Slide ${i +1}`;
            carouselIndicatorParents.appendChild(button);
        }
    }
    
})