document.querySelectorAll(".js-scrollbar").forEach((scrollbar) => {
	const scrollbarInner = scrollbar.querySelector(".inner");
	const target = document.getElementById(`${scrollbar.dataset.target}`);
	let mouseOffset = 0;
	let currentOffset = 0;
	let currentLeft = 0;

	target.addEventListener("ondrag", (e) => {
		scrollbarInner.style.left = `${e.detail.getOffset()}%`;
	});

	const updateScrollbar = () => {
		const relativeWidth = target.offsetWidth / target.scrollWidth;

		if (relativeWidth == 1 || !relativeWidth) scrollbar.classList.add("d-none");
		else scrollbar.classList.remove("d-none");

		scrollbarInner.style.width = `${
			(target.offsetWidth / target.scrollWidth) * 100
		}%`;
	};

	updateScrollbar();
	window.addEventListener("resize", updateScrollbar);

	const scrollEvent = new CustomEvent("scroll", {
		bubbles: true,
		detail: {
			getOffset: () => currentOffset,
		},
	});

	scrollbarInner.addEventListener("mousedown", (e) => {
		startScroll(e);
	});

	scrollbarInner.addEventListener("touchstart", (e) => {
		startScroll(e);
	});

	const startScroll = (e) => {
		const rect = scrollbar.getBoundingClientRect();
        const pageX = e.touches ? e.touches[0].pageX : e.pageX;

		if (pageX < rect.left) mouseOffset = 0;
		else if (pageX > rect.right) mouseOffset = 100;
		else {
			mouseOffset =
				((pageX - rect.left) / (rect.right - rect.left)) * 100;
		}
		currentLeft = parseFloat(scrollbarInner.style.left);
		if (!currentLeft) currentLeft = 0;

		enableScroll();
	};

	const enableScroll = () => {
		document.addEventListener("mousemove", move);
		document.addEventListener("mouseup", disableScroll);
        document.addEventListener("touchmove", move);
        document.addEventListener("touchend", disableScroll);
	};

	const disableScroll = () => {
		isDown = false;
		document.removeEventListener("mousemove", move);
		document.removeEventListener("mouseup", disableScroll);
        document.removeEventListener("touchmove", move);
        document.removeEventListener("touchend", disableScroll);
	};

	const move = (e) => {
        if(!e.touches) e.preventDefault();
		const rect = scrollbar.getBoundingClientRect();

        const pageX = e.touches ? e.touches[0].pageX : e.pageX;

		let offset = 0;
		if (pageX < rect.left) offset = 0;
		else if (pageX > rect.right) offset = 100;
		else {
			offset = ((pageX - rect.left) / (rect.right - rect.left)) * 100;
		}

		const currentWidth = parseFloat(scrollbarInner.style.width);
		currentOffset = currentLeft + offset - mouseOffset;

		if (currentOffset + currentWidth >= 100)
			currentOffset = 100 - currentWidth;
		else if (currentOffset < 0) currentOffset = 0;

		scrollbarInner.style.left = `${currentOffset}%`;

		target.dispatchEvent(scrollEvent);
	};
});
