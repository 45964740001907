import zilvercms from "./_utils";
import Slider from "./_slider";

export const horizontalHandler = (element) => {
	new Slider(element, {
		sliderTrackSelector: ".js-horizontal-track",
		prevArrowSelector: ".js-arrow-control[data-direction='left']",
		nextArrowSelector: ".js-arrow-control[data-direction='right']",
		initialSlide: element.dataset.selected || 0,
	});
};

document.querySelectorAll('.js-horizontal').forEach(element => {
    horizontalHandler(element);
});
