import axios from "axios";
import { Modal } from "bootstrap";
import Cookies from "js-cookie";

const requiredPageVisits = 3;
let pageLoadsLeft = parseInt(Cookies.get("newsletter")) || 0;

if (pageLoadsLeft == requiredPageVisits - 1) {
	const modalElement = document.getElementById("modal-newsletter");

	if(modalElement) {
		const modal = Modal.getOrCreateInstance(
			modalElement
		);
		modal.show();
	}
}

pageLoadsLeft++;
Cookies.set('newsletter', pageLoadsLeft, {
    expires: 7,
});

document.querySelectorAll(".js-newsletter").forEach((newsletterForm) => {
	newsletterForm.addEventListener("submit", (e) => {
		let newsletterMessage = newsletterForm.querySelector(
			".js-newsletter-message"
		);
		let submitBtn = newsletterForm.querySelector('button[type="submit"]');

		e.preventDefault();

		axios
			.post(newsletterForm.action, new FormData(newsletterForm))
			.then((response) => {
				if (response.status === 200) {
					newsletterMessage.innerHTML =
						"Bedankt voor het inschrijven op onze nieuwsbrief!";
					newsletterMessage.classList.add(
						"newsletter__message--success"
					);
					submitBtn.classList.add("disabled");
				}
			})
			.catch(() => {
				newsletterMessage.innerHTML =
					"Inschrijving mislukt. Klopt het e-mailadres?";
				newsletterMessage.classList.add("newsletter__message--danger");
			});
	});
});
