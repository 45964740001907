import axios from "axios";
import addFiltering from "./filters";
import lazyList from "./lazylist";

let isLoading = false;

const loadProducts = (params, replace = true) => {
	return new Promise(async (resolve, reject) => {
		isLoading = true;
		var filterOptions = document.querySelector(".js-filter-options");
		var productlist = document.querySelector(".js-productlist-items");

		filterOptions.classList.add("opacity-50");
		productlist.classList.add("opacity-50");

		const response = await axios.get(window.location.pathname, {
			params: params,
		});

		if (response.status !== 200) 
		{
			isLoading = false;
			reject();
		}

		var newurl =
			window.location.protocol +
			"//" +
			window.location.host +
			window.location.pathname +
			"?" +
			params.toString();
		window.history.pushState({ path: newurl }, "", newurl);

		var parser = new DOMParser();
		var doc = parser.parseFromString(response.data, "text/html");
		const newProductList = doc.querySelector(".js-productlist-items");
		const productPageCount = newProductList.dataset.pageCount;

		lazyList.pageCount = parseInt(productPageCount);

		const newProductsCount = newProductList.querySelectorAll('.productcard').length;

		if (newProductList) {
			if (replace) {
				lazyList.reset();
				productlist.replaceChildren(...newProductList.childNodes);
			} else {
				productlist.append(...newProductList.childNodes);
			}
		}

		const pagination = document.querySelector('.js-pagination');
		pagination?.remove();

		const newFilterOptions = doc.querySelector(".js-filter-options");
		if (newFilterOptions) {
			filterOptions.replaceChildren(...newFilterOptions.childNodes);
		}

		addFiltering();

		var productlistCount = document.querySelector(".js-productlist-count");
		var newTotal = filterNumber(
			doc.querySelector(".js-productlist-count").innerHTML
		);

		productlistCount.innerHTML = `${newTotal} artikelen gevonden`;

		filterOptions.classList.remove("opacity-50");
		productlist.classList.remove("opacity-50");

		isLoading = false;
		resolve(newProductsCount);
	});
};

const filterNumber = (string) => {
	var matches = string.match(/(\d+)/);

	return matches[0];
};

export { loadProducts, isLoading };
