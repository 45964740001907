$('.js-zilver-recaptcha-form').each(function() {
    var $form = $(this);
    var $response = $form.find('input[name="g-captcha-response"]');
    var action = $form.data('recaptcha-action') || 'submit';
    var key = $form.data('recaptcha-key');

    if(typeof key !== 'undefined') {
        $form.on('submit', function(e) {
            if($response.val() === '') {
                e.preventDefault();
                grecaptcha.ready(function() {
                    grecaptcha.execute(key, {action: action}).then(function(token) {
                        $response.val(token);
                        $form.submit();
                    });
                });
            }
        });
    }
});